
    @if(!resetSuccess){
    <p class="font-500 p-m-2 pt-lg-4 title">
        Glömt lösenord
    </p>

    <div class="login-section">
        <div id="login">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                <div class="mb-lg-4">
                    <input required type="password" class="form-control input-line" id="password"
                        formControlName="password" placeholder="Nytt Lösenord" autocomplete="on" />

                    <p style="text-align: start; font-size: 1rem;">
                        Lösenord måste innehålla:
                    </p>

                    <p style="text-align: start; font-size: 1rem;" [class.text-danger]="!minLengthValid">
                        - Minst 8 tecken
                    </p>
                    <p style="text-align: start; font-size: 1rem;"
                        [class.text-danger]="!uppercaseValid || !lowercaseValid">
                        - Minst en stor och liten bokstav
                    </p>
                    <p style="text-align: start; font-size: 1rem;" [class.text-danger]="!oneDigitValid">
                        - Minst en siffra
                    </p>
                    <p style="text-align: start; font-size: 1rem;" [class.text-danger]="!specialCharValid">
                        - Minst ett specialtecken
                    </p>

                </div>
                <input required type="password" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
                    class="form-control input-line" id="confirmPassword" formControlName="confirmPassword"
                    placeholder="Repetera Lösenord" autocomplete="on" />

            <div style="min-height: 1.5rem;">
                @if(!matchingPassword && showError){
                <span class="text-danger help-block">
                    Lösenorden måste vara likadana <br>
                </span>
                }
                @if(requestError){
                <span class="text-danger help-block">
                    Ett oväntat fel upstod, vänligen försök igen <br>
                </span>   
                }
            </div>
                <button type="submit">
                    <span class="fw-bold" style="color: #fff;">Återställ lösenord</span>
                </button>
            </form>
        </div>
    </div>

    }@else {
    <div style="margin-top: 3rem;">
        <p>Ditt lösenord har återställts</p>
        <a [routerLink]="['/']" style="text-decoration: underline;">logga in</a>
    </div>
    }

