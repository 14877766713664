<div>
  @if(!RequestSent){
  <form (ngSubmit)="submitResetPasswordRequest()">
    <input required type="email" [email]="email !== ''" class="form-control input-line" id="email"
      placeholder="E-postadress" [(ngModel)]="email" name="email" #resetRequestControl="ngModel" />
    <div class="errorMsgPlaceholder">
      @if(requestError){
      <span class="text-danger help-block">
        {{errorMsg}}
      </span>
      }
    </div>
    <button type="submit" [disabled]="btnDisabled || !resetRequestControl.dirty
      ">
      <p class="">Återställ lösenord</p>
    </button>
  </form>
  }@else {
  <p class="successMsg fw-bold">
    Vi har skickat ett e-postmeddelande till den angivna e-postadressen.
  </p>
  <p class="successMsg">
    Vänligen följ länken i e-postmeddelandet för att återställa ditt lösenord.
  </p>
  <p class="successMsg">
    Om du inte ser meddelandet i din inkorg, vänligen kontrollera din skräppostmapp.
  </p>
  <a [routerLink]="['/']" style="text-decoration: underline;">Logga in</a>
  }
</div>