import {createReducer, on} from '@ngrx/store';
import {HubActions} from "../state/hub.actions";

export interface HubState {
  connected: boolean;
}

export const initialState: HubState = {
  connected: false,
};

export const HubReducer = createReducer(
  initialState,
  on(HubActions.updateStatus, (state, {isConnected}) => ({
    ...state,
    connected: isConnected
  }))
);
