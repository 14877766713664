<div class="container-fluid vh-100 vw-100 gx-0 row">
    <div [ngClass]="currentSlide.class"
         class="d-flex flex-column flex-md-row text-center row g-0">
        <div class="d-none d-md-flex col flex-row container-fluid row justify-content-center">
            <div [ngStyle]="{
                     'background-image': 'url(../../'+currentSlide.dogImage+')',
                     'background-repeat':'no-repeat',
                     'background-position':'bottom 0 left 50%',
                     'background-size':'250px'
                    }"
                 class="col-auto col-md-9 d-flex flex-column align-items-center g-0 p-3 justify-content-center">
                <div [ngStyle]="{'background-color':'rgba(255,255,255,0.95)'}"
                     class="align-items-center rounded-3 shadow-sm d-flex flex-column">
                    <div class="p-3">
                        <img
                                *ngIf="currentSlide.icon"
                                alt=""
                                class="imgIcon my-3"
                                src="{{'../../assets/icons/' + currentSlide.icon + '.svg'}}"
                        />
                        <h4 [ngClass]="'text-center rounded-3 p-3'">
                            {{ currentSlide.mainText }}
                        </h4>
                        <div class="p-3">
                            {{ currentSlide.subText }}
                        </div>
                    </div>
                    <div class="slider-controller d-flex justify-content-center p-3">
                        <div
                                (click)="changeSlide(index)"
                                *ngFor="let slide of slides; let index = index"
                                [ngClass]="{ 'active-div': index === currentSlide.order }"
                                class="slider-controller-div rounded-circle m-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col slider-content flex-grow-1 container-fluid g-0 d-flex justify-content-center align-items-center row">
            <div class="outerWrapper align-self-center col-12 col-sm-11 col-md-8 col-lg-9 rounded shadow bg-white">
                <div class="logo-div bg-white rounded-circle position-absolute start-50 translate-middle-x">
                    <img alt="" height="80" src="../../assets/icons/mainG.svg"/>

                </div>
                <div class="p-4">
                    @if (showCloseButton) {
                        <span
                                role="button"
                                class="fw-bold position-absolute end-0 top-0"
                                [routerLink]="['/']">
                            <img src="../../../assets/icons/close.svg" alt="close"/>
                            </span>
                    }
                    <div class="form-content">
                        <router-outlet/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
