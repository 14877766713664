import {ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withComponentInputBinding} from '@angular/router';
import {routes} from './app.routes';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {tokenInterceptor} from './core/auth/token.interceptor';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideStore} from '@ngrx/store'
import {UserReducer} from "./reducers/user.reducer";
import {provideEffects} from '@ngrx/effects';
import {UserEffects} from "./reducers/user.effects";
import {HubReducer} from "./reducers/hub.reducer";

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    provideAnimationsAsync(),
    provideStore({['user']: UserReducer, ['hub']: HubReducer}, {
      metaReducers: []
    }),
    provideEffects([UserEffects])
  ]
};
