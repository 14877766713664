<span *ngIf="(showLogin && screenWidth < tabletWidth) || showRegister"
      role="button"
      class="fw-bold position-absolute end-0 top-0"
      (click)="hideLog()">
      <img src="../../../assets/icons/close.svg" alt="close"/>
    </span>
<p class="title font-500">
  {{ resetPassword ? "Reset password" : "Välkommen!" }}
</p>

<div class="login-section" *ngIf="!showRegister && !resetPassword">
  <div id="login"
       [ngClass]="{
           collapse: screenWidth < tabletWidth && showLogin == false,
        show: showLogin == true,
      }"
       class="h-auto"
  >
    <form (ngSubmit)="showLoginForm()">
        <span *ngIf="loginError" class="text-danger help-block">
          {{ loginError }}
        </span>
      <div class="">
        <input
          required
          type="email"
          [email]="loginData.email !== ''"
          class="form-control input-line"
          id="email1"
          aria-describedby="emailHelp"
          placeholder="E-postadress"
          [(ngModel)]="loginData.email"
          name="email"
          autocomplete=""
          #emailControl="ngModel"
        />
        <span
          class="text-danger help-block"
          *ngIf="!isEmailValid(loginData.email) && emailControl.touched"
        >
            Ogiltig e-postadress
          </span>
        <span
          class="text-danger help-block"
          *ngIf="emailControl.errors?.required && emailControl.touched"
        >
            Email fältet får inte vara tom
          </span>
      </div>
      <input
        required
        type="password"
        autocomplete="current-password"
        class="form-control input-line "
        id="exampleInputPassword"
        placeholder="Lösenord"
        [(ngModel)]="loginData.password"
        name="password"
        #passwordControl="ngModel"
      />
      <span
        class="text-danger help-block"
        *ngIf="passwordControl.errors?.required && passwordControl.touched"
      >
          Lösenord fältet får inte vara tom
        </span>

      <button type="submit" class="d-none"></button>
    </form>
    <div class="text-end">
      <a class="btn fw-bold" [routerLink]="['glomtLosenord']">
        Glömt lösenord?
      </a>
    </div>
  </div>
  <button data-bs-target="#login"
          [attr.data-bs-toggle]="showLogin ? '' : 'collapse'"
          aria-controls="login"
          aria-expanded="false"
          aria-label="Toggle login"
          (click)="showLoginForm()"
          [disabled]="isButtonDisabled"
          [ngClass]="{
    'btn-disabled': isButtonDisabled
      }">
    <span class="fw-bold" style="color: #fff;">Logga in</span>
  </button>
  <div class="create-account"
       [ngClass]="showLogin && screenWidth < tabletWidth ? 'hide-form' : ''">
    <p class="">Inte medlem?</p>
    <button class="create-account-button" (click)="showRegisterForm()">
      <span class="fw-bold" style="color: #fff;">Skapa konto</span>
    </button>
  </div>
</div>
@if (showRegister) {
  <app-register (UserRegistered)="UserRegistered($event)" (loadingStateChanged)="handleLoadingState($event)"/>
}
