import {Routes} from "@angular/router";
import {PageNotFoundComponent} from "./shared/components/page-not-found/page-not-found.component";
import {AuthLayoutComponent} from "./core/auth/auth-layout/auth-layout.component";

import {FormComponent} from "./core/auth/form/form.component";
import {CreateNewPasswordComponent} from "./core/auth/create-new-password/create-new-password.component";
import {ForgotPasswordRequestComponent} from "./core/auth/forgot-password-request/forgot-password-request.component";
import {notLoggedInGuard} from "./core/auth/not-logged-in.guard";
import {AuthGuard} from "./core/auth/auth-guard.service";

export const routes: Routes = [
  {
    path: "login",
    canActivate: [notLoggedInGuard],
    component: AuthLayoutComponent,
    children: [
      {
        path: "glomtLosenord",
        component: ForgotPasswordRequestComponent,
      },
      {
        path: "nyttLosenord",
        component: CreateNewPasswordComponent
      },
      {
        path: "",
        component: FormComponent
      }
    ]
  },
  {
    path: "",
    canActivate: [AuthGuard],
    loadChildren: () => import("src/app/core/layout/main-view.routes").then(m => m.routes),
  },
  //Wild Card Route for 404 request
  {path: "**", pathMatch: "full", component: PageNotFoundComponent},
];
