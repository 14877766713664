import {createReducer, on} from '@ngrx/store';

import {UserActions} from "../state/user.actions";
import {User} from "../shared/model/user";

export interface UserState {
  currentUser: User | undefined;
}

export const initialState: UserState = {
  currentUser: undefined,
};

export const UserReducer = createReducer(
  initialState,
  on(UserActions.setUser, (state, {newUser}) => ({currentUser: newUser})),
  on(UserActions.updateProfileImage, (state, {newImage}) => ({currentUser: {...state.currentUser!, profileImage: newImage}})),
  on(UserActions.unsetUser, (() => initialState)),
);
