import {Component, inject, Input} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {AuthService} from "../authentication.service";
import {ActivatedRoute, RouterModule} from '@angular/router';

@Component({
  selector: 'app-reset-password-page',
  standalone: true,
    imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './reset-password-page.component.html',
  styleUrl: './reset-password-page.component.css'
})

export class ResetPasswordPageComponent {

  @Input() token: string;
  @Input() email: string;

  route = inject(ActivatedRoute);
  authService = inject(AuthService);
  matchingPassword: boolean = true;
  showError: boolean;
  resetSuccess: boolean
  requestError: boolean;
  minLengthValid: boolean = false;
  uppercaseValid: boolean = false;
  lowercaseValid: boolean = false;
  specialCharValid: boolean = false;
  oneDigitValid: boolean = false;

  resetPasswordForm = new FormGroup({
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
  });

    ngOnInit() {
    // Listen for changes in confirmPassword field
    this.resetPasswordForm.get('password')?.valueChanges.subscribe(() => this.checkPasswordRules());
    this.resetPasswordForm.get('confirmPassword')?.valueChanges.subscribe(() => this.checkPassword());
  }

  checkPasswordRules() {
    const password = this.resetPasswordForm.get('password')?.value;
    if (password) {
      this.minLengthValid = password.length >= 8;
      this.uppercaseValid = /[A-Z]/.test(password);
      this.lowercaseValid = /[a-z]/.test(password);
      this.specialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      this.oneDigitValid = /\d/.test(password);
    }

  }

  checkPassword() {
    const password = this.resetPasswordForm.get('password')?.value;
    const confirmPassword = this.resetPasswordForm.get('confirmPassword')?.value;

    this.matchingPassword = password === confirmPassword;

    this.showError = !this.matchingPassword && confirmPassword !== '';
  }

  onSubmit() {
    const password = this.resetPasswordForm.get('password')?.value;
    const confirmPassword = this.resetPasswordForm.get('confirmPassword')?.value;

    if (
      this.matchingPassword &&
      password &&
      confirmPassword &&
      this.minLengthValid &&
      this.uppercaseValid &&
      this.lowercaseValid &&
      this.specialCharValid &&
      this.oneDigitValid
    ) {
      this.requestError = false;
      this.authService.sendResetPassword(this.email, password, this.token).subscribe({
          next: (res) => {
          this.resetSuccess = true;
        },
        error: (error) => {
            this.requestError = true;
        }
      });
    } else {

    }
  }

}
