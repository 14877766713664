<div>
  <form class="mt-4" (ngSubmit)="submitResetPasswordRequest()">
    <input
      pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
      required
      type="email"
      [email]="email !== ''"
      class="form-control input-line"
      id="email"
      placeholder="E-postadress"
      [(ngModel)]="email"
      (ngModelChange)="resetError()"
      name="email"
      #resetRequestControl="ngModel"
    />
    <!-- <span
    class="text-danger d-inline-block"
    *ngIf="resetRequestControl.errors?.pattern && resetRequestControl.touched"
  >
    Ogiltig e-postadress
  </span> -->
    <p class="text-danger" *ngIf="error">{{ error }}</p>
    <p class="text-info p-2" *ngIf="info">{{ info }}</p>
    <button
      type="submit"
      class="mt-4"
      [disabled]="
        resetRequestControl.errors?.pattern || !resetRequestControl.dirty
      "
    >
      <p class="">Återställ lösenord</p>
    </button>
  </form>
</div>

<!-- <div>
  <input
    type="text"
    [(ngModel)]="email"
    placeholder="Submit your email"
    class="mt-3"
  />
  <button type="submit" class="mt-4" (click)="submitResetPasswordRequest()">
    <p class="">Reset my password</p>
  </button>
</div> -->
