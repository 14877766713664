import {Component, Input} from '@angular/core';
import {FormComponent} from "../form/form.component";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {NavigationEnd, Router, RouterModule, RouterOutlet} from "@angular/router";
import {SliderService} from "../../../shared/service/slider-service.service";
import {ResetPasswordPageComponent} from 'src/app/core/auth/reset-password-page/reset-password-page.component';
import {filter, Subscription} from 'rxjs';

@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [
    FormComponent,
    CommonModule,
    RouterOutlet,
    NgOptimizedImage,
    ResetPasswordPageComponent,
    RouterModule,
  ],
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.css'
})
export class AuthLayoutComponent {
  public bg = "#785f66";

  showCloseButton: boolean = false;
  private routerSubscription: Subscription;

  changeBg(color: string): void {
    this.bg = color;
  }

  @Input() loginState: boolean
  loginStateService: boolean
  constructor(private sliderService: SliderService, private router: Router) {
    this.sliderService.onToggleSlider().subscribe(loginState => {
      this.loginStateService = loginState
      if(loginState){
        this.stopSlides()
      }else{
        this.rollSlides()
      }
    })
  }
  public slides: Slide[] = [
    {
      class: "slide1",
      icon: "mainG",
      mainText: "Välkommen till PetPals!",
      subText: "Sidan för djurägare ",
      order: 0,
      bgColor: "#785f66",
      wrapper: "wrapper1",
      dogImage: "assets/images/dog1.png"
    },
    {
      class: "slide2",
      icon: "activityG",
      mainText: "Träning, aktiviteter  och evenemang!",
      subText:
        "Hitta träningstips, roliga aktiviteter och evenemang för dig och ditt djur. ",
      order: 1,
      bgColor: "#7c6c42",
      wrapper: "wrapper2",
      dogImage: "assets/images/dog2.png"
    },
    {
      class: "slide3",
      icon: "placesG",
      mainText: "Djurvänliga platser!",
      subText:
        "Hitta till djurvänliga platser, cafén, resturanger, hotell och flyg. ",
      order: 2,
      bgColor: "#306b56",
      wrapper: "wrapper3",
      dogImage: "assets/images/dog3.png"
    },

    {
      class: "slide4",
      icon: "friendsG",
      mainText: "Hitta vänner!",
      subText:
        "Dela bilder, erfarenheter och aktiviteter med dina vänner eller hitta nya vänner! ",
      order: 3,
      bgColor: "#343952",
      wrapper: "wrapper4",
      dogImage: "assets/images/dog4.png"
    },
  ];
  stopSlide: Slide = {
    class: "slide1",
    icon: null,
    mainText: null,
    subText: null,
    order: 0,
    bgColor: "#785f66",
    wrapper: "wrapper1",
    dogImage: "assets/images/dog1.png"
  };
  setIntervalId: any = null;
  public slideIndex: number = 0;
  public currentSlide: Slide = this.slides[this.slideIndex];
  public delayTime: number = 5000;
  ngOnInit(): void {
    this.checkUrl(this.router.url);

    this.routerSubscription = this.router.events
    .pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    )
    .subscribe((event: NavigationEnd) => {
      this.checkUrl(event.url); // Check URL on navigation change
    });
    this.rollSlides();
  }

  private checkUrl(url: string): void {
    const currentPath = url.split('?')[0]; // Strip query parameters
    this.showCloseButton = currentPath.includes('glomtLosenord') || currentPath.includes('nyttLosenord');
  }

  public rollSlides() {
    // console.log("start slider");
    this.currentSlide = this.slides[0]
    if(this.setIntervalId !== null){
      clearInterval(this.setIntervalId)
    }


    this.setIntervalId = setInterval(() => {
      if (this.slideIndex === this.slides.length) {
        this.slideIndex = 0;
      }
      this.currentSlide = this.slides[this.slideIndex];
      this.changeBg(this.slides[this.slideIndex].bgColor);
      this.slideIndex++;
    }, this.delayTime);
  }
  public stopSlides() {
    // console.log('stop slider: ')
    clearInterval(this.setIntervalId);
    this.currentSlide = this.stopSlide
    this.changeBg(this.stopSlide.bgColor);
  }

  public changeSlide(index: number) {
    this.slideIndex = index;
    this.currentSlide = this.slides[this.slideIndex];
    this.changeBg(this.slides[this.slideIndex].bgColor);
  }
}

interface Slide {
  class: string;
  icon: string | null;
  mainText: string | null;
  subText: string| null;
  order: number;
  bgColor: string;
  wrapper: string;
  dogImage: string;
}

