import { Component, inject } from "@angular/core";
import { AuthService } from "../authentication.service";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { Unsub } from "src/app/shared/unsub.class";
import { RouterModule } from "@angular/router";

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule],
  selector: "app-reset-password-request",
  templateUrl: "./forgot-password-request.component.html",
  styleUrls: ["./forgot-password-request.component.css"],
})
export class ForgotPasswordRequestComponent extends Unsub {
  email: string = "";
  info: string = "";
  errorMsg: string = "";

  RequestSent: boolean = false;
  requestError: boolean = false;
  btnDisabled: boolean = false;

  authService = inject(AuthService)

  // Send request to reset password
  submitResetPasswordRequest() {
    this.btnDisabled = true;
    this.requestError = false;
    const regex = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const match = regex.test(this.email);
    if (!match) {
      this.requestError = true;
      this.errorMsg = "Ogiltlig e-postadress";
      this.btnDisabled = false;
      return;
    }
    this.authService.sendResetPasswordRequest(this.email).subscribe({
      next: (res) => {
        this.RequestSent = true;
        this.btnDisabled = false;
      },
      error: (error) => {
        this.btnDisabled = false;
        this.requestError = true;
        this.errorMsg = "Ett oväntat fel upstod, vänligen försök igen";
      }
    });

  }
}
