import {Injectable} from "@angular/core";

import {AuthService} from "./authentication.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) {}

  canMatch() {
    if (!this.authenticationService.isTokenExpired()) {
      return true;
    }
    this.authenticationService.cleanTokenData();
    return false;
  }
}
