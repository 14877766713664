import {CanMatchFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "./authentication.service";
import {map} from "rxjs/operators";

export const notLoggedInGuard: CanMatchFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.AuthenticateUser().pipe(
    map(isLoggedIn => {
      if (!isLoggedIn) {
        return true;
      }
      router.navigate(['/']).then();
      return false;
    }),
  );
}
