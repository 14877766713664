import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SliderService {
  private loginState: boolean = false;
  private subject = new Subject<any>();
  constructor() {}

  toggleSlider(state: boolean) {
    
    this.loginState = state
    this.subject.next(this.loginState);
  }

  onToggleSlider(): Observable<any> {
    return this.subject.asObservable();
  }
}
