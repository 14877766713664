import {HttpInterceptorFn} from '@angular/common/http';

/*
    Lägger till JWT-token vid utgående API-anrop
*/

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const clonedReq = req.clone({
    withCredentials: true,
  })
  return next(clonedReq);
};
