import {Routes} from "@angular/router";
import {PageNotFoundComponent} from "./shared/components/page-not-found/page-not-found.component";
import {AuthGuardService} from "./core/auth/auth-guard.service";

import {notLoggedInGuard} from "./core/auth/not-logged-in.guard";
import {AuthLayoutComponent} from "./core/auth/auth-layout/auth-layout.component";

import {ResetPasswordRequestComponent} from "./core/auth/reset-password-request/reset-password-request.component";
import {ResetPasswordPageComponent} from "./core/auth/reset-password-page/reset-password-page.component";
import {FormComponent} from "./core/auth/form/form.component";

export const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("src/app/core/layout/main-view.routes").then(m => m.routes),
    canMatch: [AuthGuardService]
  },
  {
    path:"",
    canMatch:[notLoggedInGuard],
    component:AuthLayoutComponent,
    children:[
      {
        path: "glomtLosenord",
        component: ResetPasswordRequestComponent,
      },
      {
        path: "nyttLosenord",
        component: ResetPasswordPageComponent
      },
      {
        path: "",
        component: FormComponent
      }
    ]
  },
  //Wild Card Route for 404 request
  { path: "**", pathMatch: "full", component: PageNotFoundComponent},
];
