import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {UserActions} from "../state/user.actions";
import {mergeMap} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {ApiService} from "../shared/service/http.service";
import {User} from "../shared/model/user";

const userRoutes = environment.api.user;

@Injectable()
export class UserEffects {
  validateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getLoggedInUser),
      mergeMap(() => this.api.Get<User | null>(userRoutes.getUser)
        .pipe(
          map(user => {
            const validUser = user !== null;
            if (validUser)
              UserActions.setUser({newUser: user});
            return ({type: '[UserAPI] Login Success', payload: validUser});
          }),
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {
  }
}
