import {Component, EventEmitter, HostListener, inject, Input, OnInit, Output, ViewChild,} from "@angular/core";
import {FormsModule, NgForm} from "@angular/forms";


import {Router, RouterModule} from "@angular/router";
import {AuthService} from "../authentication.service";
import {CommonModule} from "@angular/common";
import {RegisterComponent} from "../register/register.component";
import {UserService} from "src/app/features/user/user.service";
import {AuthToken} from "src/app/shared/model/auth-token";
import {Login} from "src/app/shared/model/login";
import {AnimalProfileService} from "src/app/features/animal/animal-profile.service";
import {SliderService} from "src/app/shared/service/slider-service.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {UserActions} from "../../../state/user.actions";
import {User} from "../../../shared/model/user";

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, RegisterComponent, RouterModule],
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  @ViewChild("resetForm", {static: false}) resetForm: NgForm;

  fadeInElement: HTMLElement;
  public loading: boolean = false;
  @Input() resetPassword: boolean;
  Store = inject(Store);
  resetPasswordData: { password: string; confirmPassword: string } = {
    password: "",
    confirmPassword: "",
  };
  loginError: string = "";
  sendResetPasswordRequest: boolean = false;
  sendResetPasswordRequestData: { email: string } = {email: ""};
  noMatchPasswords: boolean = false;
  @Output() loginState: EventEmitter<boolean> = new EventEmitter();
  public showMessage: boolean;
  public loginData: Login;
  public inloggedUser: AuthToken;
  public showLogin: boolean = false;
  public showRegister: boolean = false;
  public screenWidth: number = 500;
  public tabletWidth: number = 744;
  isSmallScreen: boolean;
  router = inject(Router);

  constructor(
    private authenticationService: AuthService,
    private userService: UserService,
    private animalService: AnimalProfileService,
    private sliderService: SliderService,
  ) {
  }

  get isButtonDisabled(): boolean {

    if (this.isSmallScreen) {
      return false;
    }
    return !this.isEmailValid(this.loginData.email) || !this.loginData.password;
  }

  handleLoadingState(isLoading: boolean) {
    this.loading = isLoading;
  }

  ngOnInit(): void {

    this.loginData = new Login();

    this.loginData.email = "";
    this.loginData.password = "";
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > this.tabletWidth) {
      this.showLogin = true;
    }
    if (this.screenWidth < this.tabletWidth) {
      this.isSmallScreen = true;
    }
    localStorage.removeItem("messageHubId");
    localStorage.removeItem("notificationHubId");
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.checkScreenSize();
  }

  public login() {
    this.loginError = "";
    this.authenticationService
      .loginUser(this.loginData)
      .subscribe({
        next: async (res) => {
          this.inloggedUser = res;
          if (!this.checkIfTokenEmpty()) {
            const user = this.inloggedUser.user as User;
            this.Store.dispatch(UserActions.setUser({newUser: user}));
            this.animalService.getUserAnimals(this.inloggedUser.user.id);
            await this.router.navigate(["/"], {onSameUrlNavigation: "reload"})
          }
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            if (error.error.errors && error.error.errors.length > 0) {
              const firstError = error.error.errors[0];

              if (firstError.message == "Wrong credentials") {
                this.loginError = "Felaktiga inloggningsuppgifter.";
              } else {
                this.loginError = "Ett oväntat fel inträffade. Vänligen försök igen.";
              }

            }
          } else if (error.status === 500) {
            this.loginError = "Ett oväntat fel inträffade. Vänligen försök igen.";
          } else {
            this.loginError = "Ett oväntat fel inträffade. Vänligen försök igen.";
          }
        }
      });
  }

  public checkIfTokenEmpty(): boolean {
    if (this.inloggedUser.token === "") {
      this.message(true);
      return true;
    } else {
      this.message(false);
      return false;
    }
  }

  public message(show: boolean) {
    if (show) {
      this.showMessage = true;
    } else {
      this.showMessage = false;
    }
  }

  public showLoginForm() {

    if (this.showLogin || this.screenWidth > this.tabletWidth) {
      this.login();
    } else {
      this.showLogin = true;
      this.showRegister = false;
      this.loginState.emit(this.showLogin);
      this.sliderService.toggleSlider(this.showLogin);
    }
  }

  public showRegisterForm() {

    this.showRegister = true;
    if (this.screenWidth < this.tabletWidth) {
      this.sliderService.toggleSlider(this.showRegister);
    }
  }

  public hideLog() {
    if (this.screenWidth < this.tabletWidth) {
      this.showLogin = false;
      this.loginState.emit(this.showLogin);
      this.sliderService.toggleSlider(this.showLogin);
    }
    this.showRegister = false;
  }

  UserRegistered(obj: any) {

    this.loginData.email = obj.email;
    this.loginData.password = obj.password;

    this.hideLog();
    this.showLoginForm();

  }

  checkSame(input: string) {
    const secondPassword = input;
    const firstPassword = this.resetPasswordData.password;
    if (secondPassword !== firstPassword) {

      this.noMatchPasswords = true;
      this.resetForm.controls.confirmPassword.markAsDirty();
      this.resetForm.form.controls.confirmPassword.setErrors(null);
    } else {

      this.noMatchPasswords = false;
      this.resetForm.form.controls.confirmPassword.setErrors({
        incorrect: true,
      });
      this.resetForm.controls.confirmPassword.markAsPristine();
    }
  }

  openModal() {


    this.sendResetPasswordRequest = true;
  }

  closeModal() {
    this.sendResetPasswordRequest = false;
  }

  submitResetPassword() {
    if (
      this.noMatchPasswords ||
      !this.resetPasswordData.confirmPassword ||
      !this.resetPasswordData.password
    ) {
      return;
    }


  }

  isEmailValid(email: string): boolean {

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return emailPattern.test(email);
  }

  private checkScreenSize() {
    this.isSmallScreen = window.innerWidth <= 743.8;
  }

}
