import {inject} from "@angular/core";
import {CanActivateFn, Router} from "@angular/router";
import {map} from "rxjs/operators";
import {AuthService} from "./authentication.service";

export const AuthGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authService = inject(AuthService);
  return authService.AuthenticateUser().pipe(
    map((isLoggedIn) => {
      if (isLoggedIn) {
        return true;
      }
      router.navigate(['/login']).then();
      return false;
    }),
  );
}
