import {Component, inject} from "@angular/core";
import {AuthService} from "../authentication.service";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {Unsub} from "src/app/shared/unsub.class";
import {firstValueFrom} from "rxjs";

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: "app-reset-password-request",
  templateUrl: "./reset-password-request.component.html",
  styleUrls: ["./reset-password-request.component.css"],
})
export class ResetPasswordRequestComponent extends Unsub {
  email: string = "";
  error: string = "";
  info: string = "";

  auth = inject(AuthService)

  resetError() {
    this.info = "";
    this.error = "";
  }
  // Send request to reset password
  submitResetPasswordRequest() {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/;
    const match = regex.test(this.email);
    if (!match) {
      this.error = "Email is not valid";
      return;
    }
    firstValueFrom(this.auth.sendResetPasswordRequest(this.email)).then(console.log);
  }
}
